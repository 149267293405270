import React from 'react';
import './App.css';

import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

export const Footer = () => {
    return (<div className="Footer" >
      <div>Website by <a href={"https://janeinmotion.com"} target={"_blank"}>Jane Abernethy </a></div>
  
    </div>)
  }