import './App.css';
import React, { useEffect, useState, useRef, CSSProperties } from 'react';
import { Vector2, Vector3 } from './geometry';
import { createGlowTrailShader } from './basic_shader';
import { AnimatorSvg, InstagramSvg, LinkedInSvg, SoftwareEngineerSvg, Transition } from './AnimatedSvgs';
import { updateBuffer, createBuffer, setBuffer } from './gl_base';
import { Geom } from './geom';
import gsap, { SteppedEase } from 'gsap';
import ScrollToPlugin from 'gsap/ScrollToPlugin';
import { SoftwareEngineerSection } from './SoftwareEngineer';
import { AboutMe } from './About';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import  GutsApp from './guts/GutsApp';
const TRAIL_COLOR = new Vector3(0.764, 1, 0.576);
const TRAIL_LENGTH = 16;
const OFFSCREEN_POS = new Vector2(-1, -1);
const MOUSE_IDLE_TIME_OFFSET = 50;
const GLOW_RADIUS = 20;

function App() {

  return (
    <Router>
      <Routes>
        <Route path="/" element={<PortfolioHome />} />
        <Route path="guts" element={<GutsApp />} />
      </Routes>
    </Router>
  );
}


const PortfolioHome = () => {
  const [selectedIndex, setSelectedIndex] = React.useState(0);


  const [isMobile, setIsMobile] = useState(false);


  useEffect(() => {

      document.title = "Jane Abernethy";

  
    const handleResize = () => {
      setIsMobile(window.innerWidth < 600);
    };

    // Initial check
    handleResize();

    // Event listener for window resize
    window.addEventListener('resize', handleResize);

    // Cleanup
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);


  return (
    <div className="App">
      <Pages isMobile={isMobile} selectedIndex={selectedIndex} didSelectIndex={(index: number) => setSelectedIndex(index)} />
      {!isMobile && selectedIndex === 0 &&
        <ContactFooter isMobile={isMobile} selectedIndex={selectedIndex} />
      }
      {isMobile && (selectedIndex !== 1) &&
        <div className='MobileBottomFooter'>
          <div style={{ height: 35 }} onClick={() => { window.open('https://www.linkedin.com/in/jabernethy', '_blank'); }}>
            <LinkedInSvg hovered={false} height={35} />
          </div>
          <div style={{ height: 35 }} onClick={() => { window.open('https://www.instagram.com/jane.in.motion', '_blank'); }}>
            <InstagramSvg hovered={false} height={35} />
          </div>
          {/* <div onClick={() => { window.open('https://www.instagram.com/jane.in.motion', '_blank'); }}>
          <Mail height={60} />
        </div> */}
        </div>
      }

    </div>
  )

}

function ContactFooter({ isMobile, selectedIndex }: { isMobile: boolean, selectedIndex: number }) {
  const hoverColor = "#fd1174";
  const normalColor = "#0d1216";

  const [hovered, setHovered] = useState(-1);


  return (<div className='FooterContainer' style={{ width: !isMobile && selectedIndex === 1 ? "50%" : "100%" }}>

    <div style={{ height: 25 }} className='contactItem' onClick={() => {
      window.open('https://www.linkedin.com/in/jabernethy', '_blank');
    }} onMouseEnter={() => setHovered(0)} onMouseLeave={() => setHovered(-1)}>
      <LinkedInSvg hovered={hovered === 0} height={25} />
    </div>
    <div style={{ height: 25 }} onClick={() => {

      window.open('https://www.instagram.com/jane.in.motion', '_blank');
    }} className='contactItem' onMouseEnter={() => setHovered(1)} onMouseLeave={() => setHovered(-1)}>
      <InstagramSvg hovered={hovered === 1} height={25} />
    </div>
    <div className='contactItem' style={{ cursor: 'default' }} onMouseEnter={() => setHovered(2)} onMouseLeave={() => setHovered(-1)}>
      jane@janeinmotion.com
    </div>
  </div>)
}

function Pages({ selectedIndex, isMobile, didSelectIndex }: { selectedIndex: number, isMobile: boolean, didSelectIndex: (index: number) => void }) {

  const prevValueRef = useRef(0);
  useEffect(() => {
    prevValueRef.current = selectedIndex;
    console.log("Changed")

  }, [selectedIndex])

  return (
    <div style={{ height: selectedIndex === 0 ? "200%" : "100%" }} className='Pages'>
      {selectedIndex === 0 &&
        <div className='Home'>
          <Home selectedIndex={selectedIndex} isMobile={isMobile} showAbout={() => didSelectIndex(1)} />
          <SoftwareEngineerSection isMobile={isMobile} />
        </div>
      }
      {selectedIndex === 1 &&

        <AboutMe isMobile={isMobile} onClose={() => didSelectIndex(0)} />
      }
    </div>
  )
}


function Home({ isMobile, showAbout, selectedIndex }: { selectedIndex: number, isMobile: boolean, showAbout: () => void }) {
  gsap.registerPlugin(ScrollToPlugin)



  const [animationTextVisible, setAnimationTextVisible] = useState(false);
  const [softwareTextVisible, setSoftwareTextVisible] = useState(false);
  const [softwareHovered, setSoftwareHovered] = useState(false);
  const [animationHovered, setAnimationHovered] = useState(false);
  const scrollToSoftwarePage = () => {

    gsap.to(window, { duration: 0.5, scrollTo: "#SoftwareEngineeringContainer", position: 0 });

  }

  useEffect(() => {

    //show softwareText
    const timer1 = setTimeout(() => {
      setSoftwareTextVisible(true);
    }, 1000);


    const timer2 = setTimeout(() => {
      setAnimationTextVisible(true);
    }, 6000);

    return () => {
      clearTimeout(timer1);
      clearTimeout(timer2);
    }
  }, [])

  return (
    <div className={isMobile ? `HomeContents HomeContentsMobile` : `HomeContents HomeContentsDesktop`}>

      {!isMobile &&
        <CreativeTechGlow />
      }

      {!isMobile &&
        <div onClick={showAbout} className='AboutLinkDesktop'>About</div>
      }

      <div className='HomeContentsText'>
        <div className='HomeContentsTextInner'>
          <div className={isMobile ? `HomeContextTextTitle HomeContextTextTitleMobile` : `HomeContextTextTitle HomeContextTextTitleDesktop`} style={{ fontSize: isMobile ? 36 : 52 }}>
            Jane Abernethy
          </div>

          <div className={isMobile ? `HomeContextTextSubtitle HomeContextTextSubtitleMobile` : `HomeContextTextSubtitle HomeContextTextSubtitleDesktop`} style={{ fontSize: isMobile ? 18 : 25 }}>


            <div style={{ width: 190, height: 30, cursor: 'pointer' }} onMouseEnter={() =>
              setSoftwareHovered(true)
            }
              onMouseLeave={() => setSoftwareHovered(false)} onClick={() => scrollToSoftwarePage()}>
              {softwareTextVisible &&
                <SoftwareEngineerSvg hovered={softwareHovered} />
              }
            </div >
            <div onMouseOver={() => {
              setAnimationHovered(true)
            }}
              onClick={() => {
                window.open("https://youtu.be/SZtSDFrZGrc", "_blank");
              }}
              onMouseLeave={() => setAnimationHovered(false)} className={`AnimatorSvgContainer ${animationTextVisible ? 'visible' : 'hidden'}  `}
              style={{
                cursor: 'pointer',
                width: 76, paddingTop: 2, height: 30
              }}>
              {animationTextVisible &&
                <AnimatorSvg hovered={animationHovered} />
              }
            </div >
          </div>
        </div>
      </div>
      {isMobile &&
        <div className='MobileMenu'>
          <div className='MobileMenuItem' onClick={() => { scrollToSoftwarePage(); }}><div className='MobileMenuIcon'>💻</div><div className='MobileMenuText'>Software Engineering</div></div>
          <div className='MobileMenuItem' onClick={() => { window.open("https://www.youtube.com/embed/jaPPAJ5jB58", "_blank"); }}><div className='MobileMenuIcon'>🎞️</div><div className='MobileMenuText'>Animation</div></div>
          <div className='MobileMenuItem' onClick={() => { showAbout() }}><div className='MobileMenuIcon'>🤸‍♀️</div><div className='MobileMenuText'>About</div></div>
        </div>
      }
      {isMobile &&
        <div style={{ height: 200 }} />
      }
    </div >
  )
}




const CreativeTechGlow = () => {
  const canvasRef = useRef<HTMLCanvasElement>(null);

  const [pageSize, setPageSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  useEffect(() => {
    const handleResize = () => {
      setPageSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    }

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    let renderer: Renderer;

    const handleMouseMove = (event: MouseEvent) => {
      if (!canvasRef.current) {
        return;
      }

      const { top, left, bottom, right } = canvasRef.current.getBoundingClientRect();
      if (
        event.clientX >= left
        && event.clientY >= top
        && event.clientX <= right
        && event.clientY <= bottom
      ) {
        const now = Date.now();
        const posX = event.clientX - left;
        const posY = event.clientY - top + 8;
        renderer?.updatePoint(new Vector2(posX, posY), now + MOUSE_IDLE_TIME_OFFSET);
      }
    };

    document.addEventListener('mousemove', handleMouseMove);

    const initialize = () => {
      if (!renderer && canvasRef.current) {
        const canvas = canvasRef.current;
        const ctx = canvas.getContext('webgl2');
        if (!ctx) {
          return;
        }
        renderer = new Renderer(canvas, ctx);
        requestAnimationFrame(animateTrail);
      }
    };

    const animateTrail = () => {
      renderer?.animateTrail();
      requestAnimationFrame(animateTrail);
    };

    // why? react-measure seems to give stale values
    const id = window.setTimeout(() => {
      initialize();
    }, 100);

  }, []);

  return (
    <div style={{ position: 'absolute' }}>
      <canvas
        ref={canvasRef}
        width={pageSize.width}
        height={pageSize.height}
        className={"background"}
      />
    </div>
  );
}



class Renderer {
  private readonly ctx: WebGL2RenderingContext;
  private size: Vector2;
  private canvas: HTMLCanvasElement;
  private readonly squarePoints: WebGLBuffer;
  private readonly basicShader: any;
  private readonly mousePositions: Vector2[];
  private point = OFFSCREEN_POS;
  private trailTimeCap: number | undefined;
  private radius = GLOW_RADIUS;

  constructor(
    canvas: HTMLCanvasElement,
    ctx: WebGL2RenderingContext,
  ) {
    this.ctx = ctx;
    this.canvas = canvas;
    this.size = new Vector2(canvas.width, canvas.height);
    this.squarePoints = createBuffer(
      this.ctx,
      Array.from(
        {
          length: 4 * 4,
        },
        () => 0,
      ),
    );
    this.basicShader = createGlowTrailShader(this.ctx);
    this.mousePositions = new Array<Vector2>();
    for (let i = 0; i < TRAIL_LENGTH; i++) {
      this.mousePositions.push(OFFSCREEN_POS); // initialise off screen
    }

    const program = this.basicShader;
    this.ctx.useProgram(program.program);

    updateBuffer(
      this.ctx,
      this.squarePoints,
      flattenVector3ToMat4([
        new Vector3(0, 0, 0),
        new Vector3(0, 1, 0),
        new Vector3(1, 1, 0),
        new Vector3(1, 0, 0),
      ]),
    );
    setBuffer(this.ctx, program.access.pos, this.squarePoints, 4);
  }

  clear() {
    this.ctx.disable(this.ctx.DEPTH_TEST);
    this.ctx.disable(this.ctx.CULL_FACE);
    this.ctx.clearColor(0.0, 0.0, 0.0, 0.0);
    this.ctx.enable(this.ctx.BLEND);
    this.ctx.clear(
      this.ctx.COLOR_BUFFER_BIT | this.ctx.DEPTH_BUFFER_BIT | this.ctx.STENCIL_BUFFER_BIT,
    );
  }

  animateTrail() {
    this.clear();
    if (!this.trailTimeCap || Date.now() < this.trailTimeCap) {
      this.radius = GLOW_RADIUS;
      this.draw();
    } else {
      if (this.radius > 0) {
        this.radius -= 2;
        this.draw();
      } else {
        this.clearMousePositions();
      }
    }
  }

  updatePoint(newPoint: Vector2, trailTimeCap: number) {
    this.point = newPoint;
    this.trailTimeCap = trailTimeCap;
  }

  clearMousePositions() {
    // prevents long straight lines when  banner is left & re entered somewhere else
    for (let i = 0; i < TRAIL_LENGTH; i++) {
      this.mousePositions[i] = OFFSCREEN_POS;
    }
  }

  draw() {
    const movedPos = this.mousePositions.some(pos => {
      return !Geom.approximatelyEquals(this.point, pos);
    });

    if (!movedPos) {
      return;
    }

    const program = this.basicShader;
    this.ctx.useProgram(program.program);

    // remaps default webgl coordinates to unit coordinates
    const layoutMatrix = [2, 0, 0, 0, 0, -2, 0, 0, 0, 0, 1, 0, -1, 1, 0, 1];
    this.ctx.uniformMatrix4fv(program.access.mat, false, layoutMatrix);
    this.ctx.uniform4fv(
      program.access.color,
      new Float32Array([TRAIL_COLOR.x, TRAIL_COLOR.y, TRAIL_COLOR.z, 0]),
    );

    // prevents a strong line starting from the top left corner when the trail starts / restarts by initialising all points to the first mouse move position
    for (let i = 0; i < this.mousePositions.length; i++) {
      if (Geom.approximatelyEquals(this.mousePositions[i], OFFSCREEN_POS)) {
        this.mousePositions[i] = this.point;
      }
    }

    this.mousePositions.pop();
    this.mousePositions.unshift(this.point);
    this.ctx.uniform2fv(program.access.mousePositions, flattenVector2(this.mousePositions));

    this.canvas.width = this.canvas.clientWidth;
    this.canvas.height = this.canvas.clientHeight;
    this.ctx.viewport(0, 0, this.canvas.width, this.canvas.height);
    this.size = new Vector2(this.canvas.width, this.canvas.height);
    this.ctx.uniform1f(program.access.radius, this.radius);

    this.ctx.uniform2fv(program.access.screenSize, new Float32Array([this.size.x, this.size.y]));
    this.ctx.drawArrays(this.ctx.TRIANGLE_FAN, 0, 4);
  }
}

// flattens vector3 to a list of numbers (using Vector length 4)
function flattenVector3ToMat4(points: Vector3[]): number[] {
  const rv: number[] = [];
  for (const point of points) {
    rv.push(point.x);
    rv.push(point.y);
    rv.push(point.z);
    rv.push(1);
  }
  return rv;
}

function flattenVector2(points: Vector2[]): number[] {
  const rv: number[] = [];
  for (const point of points) {
    rv.push(point.x);
    rv.push(point.y);
  }
  return rv;
}

export default App;
