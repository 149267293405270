import React from 'react';
import './App.css';

import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

export const Selections = React.memo(({ isMobile, selectionRef }: { isMobile: boolean, selectionRef: React.RefObject<HTMLDivElement> }) => {

  const selectionImgsRef = React.useRef<HTMLDivElement>(null);

  React.useEffect(() => {
    let ctx = gsap.context(() => {
    if (!selectionRef.current) {
      return
    }

    if (!isMobile) {
      gsap.fromTo(
        selectionRef.current.children,
        { opacity: 0, y: 20 }, // Initial state
        {
          opacity: 1,
          y: 0,
          stagger: 0.1, // Delay between each div animation
          duration: 0.5,
          scrollTrigger: {
            trigger: selectionRef.current,
            start: 'top 50%', // Trigger when container reaches 80% into viewport
            toggleActions: 'play none none reset',
          },
        }
      );
    }
    else {
      if (selectionImgsRef.current) {
        gsap.fromTo(
          selectionImgsRef.current.children,
          { opacity: 0, y: 20 }, // Initial state
          {
            opacity: 1,
            y: 0,
            stagger: 0.2, // Delay between each div animation
            duration: 0.5,
            scrollTrigger: {
              trigger: selectionImgsRef.current,
              start: 'top 80%',
              toggleActions: 'play none none reset',
            },
          }
        );
      }
    }});
    return ()=> { ctx.revert()}

  }, [isMobile]);


  return (
    <div ref={selectionRef} className={isMobile ? 'selectionOuterMobile' : 'selectionOuter'} style={{ opacity: 1 }}>
      <div className={isMobile ? 'selectionHeaderMobile' : 'selectionHeader'} style={{}}>
        <div className={isMobile ? "selectionHeaderTextMobile" : "selectionHeaderText"}>
          Selections
        </div>
      </div>
      <div ref={selectionImgsRef} className={isMobile ? 'selectionContainerMobile' : 'selectionContainer'}>
        <div onClick={() => window.open("https://liaf.org.uk/schedule/liaf-2024-british-showcase-15-filmmaker-introductions?wcs_timestamp=1732309200")} className='selectionImageContainer'>
          <img className='selectionImage' src="/guts/LIAF.png" />
        </div>
        <div onClick={() => window.open("https://www.animation-nation.co/")} className='selectionImageContainer'>
          <img className='selectionImage' src="/guts/AnimationNation.png" />
        </div>
      </div>
    </div>)
});
