import React from 'react';
import './App.css';

import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';


export const Menu = ({ currentItem, updateCurrentSection }: { currentItem: number, updateCurrentSection: (newSection: number) => void }) => {
    React.useEffect(() => {
      // console.log("currentItem " + currentItem)
    }, [currentItem])
  
    return (
      <div className="menu">
        <div onClick={() => updateCurrentSection(0)} className='menuItem fredoka-title '>
          <div className="menuText">Home</div>
          {currentItem === 0 &&
            <div className="menuUnderline" />
          }
        </div>
        <div onClick={() => updateCurrentSection(1)} className='menuItem fredoka-title '>
          <div className="menuText">About</div>
          {currentItem === 1 &&
            <div className="menuUnderline" />
          }
        </div>
        <div onClick={() => updateCurrentSection(2)} className='menuItem fredoka-title '>
          <div className="menuText">Selections</div>
          {currentItem === 2 &&
            <div className="menuUnderline" />
          }
        </div>
        <div onClick={() => updateCurrentSection(3)} className='menuItem fredoka-title '>
          <div className="menuText">Credits</div>
          {currentItem === 3 &&
            <div className="menuUnderline" />
          }
        </div>
      </div>
    )
  }
  
  