import React from 'react';
import './App.css';

import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

export const Landing = ({ landingRef }: { landingRef: React.RefObject<HTMLDivElement> }) => {
  return <div ref={landingRef} className='landing'>
    <div className='videoContainer'>
      <video muted className="landingVideo" loop autoPlay>
        <source src='/guts/LandingVideo.mp4' type='video/mp4' />
        Your browser does not support the video tag.

      </video>
    </div>
  </div>
}