
import React from 'react';
import logo from './logo.svg';
import './App.css';

import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

const GSAP_TRIGGER_MOBILE = "top 70%"
const GSAP_TRIGGER_DESKTOP = "top 80%"
const GSAP_STAGGER = 0.2;
const GSAP_DURATION = 0.5;
const GSAP_TOGGLE_ACTIONS = 'play none none reset';


export const Crew = React.memo(({ crewRef, height, isMobile }: { crewRef: React.RefObject<HTMLDivElement>, height: number, isMobile: boolean }) => {
    gsap.registerPlugin(ScrollTrigger);


    React.useEffect(() => {


        let ctx = gsap.context(() => {
            if (!crewRef.current) {
                return
            }
            if (isMobile) {
                return;
            }
            gsap.fromTo(
                crewRef.current.children,
                { opacity: 0.5, y: 20 },
                {
                    opacity: 1,
                    y: 0,
                    stagger: GSAP_STAGGER,
                    duration: GSAP_DURATION,
                    scrollTrigger: {
                        trigger: crewRef.current,
                        end: "bottom 0%",
                        start: isMobile ? GSAP_TRIGGER_MOBILE : GSAP_TRIGGER_DESKTOP,
                        toggleActions: GSAP_TOGGLE_ACTIONS,
                    },
                }
            );
            return () => { ctx.revert() };
        })

    }, [height]);

    return <div ref={crewRef} className={isMobile ? 'crewMobile' : 'crew'}>
        <FilmByJane isMobile={isMobile} />
        <CoreCrew isMobile={isMobile} />
        <CoreCrew2 isMobile={isMobile} />
        <Tutors isMobile={isMobile} />
        <TechSupport isMobile={isMobile} />
        <SpecialThanks isMobile={isMobile} />
    </div>
});


const FilmByJane = React.memo(({ isMobile }: { isMobile: boolean }) => {
    const containerRef = React.useRef<HTMLDivElement>(null);

    React.useEffect(() => {
        if (!isMobile) {
            return
        }
        let ctx = gsap.context(() => {
            if (!containerRef.current) {
                return
            }
            gsap.fromTo(
                containerRef.current.children,
                { opacity: 0, y: 20 },
                {
                    opacity: 1,
                    y: 0,
                    stagger: GSAP_STAGGER,
                    duration: GSAP_DURATION,
                    scrollTrigger: {
                        trigger: containerRef.current,
                        start: isMobile ? GSAP_TRIGGER_MOBILE : GSAP_TRIGGER_DESKTOP,
                        toggleActions: GSAP_TOGGLE_ACTIONS,
                    },
                }
            );
        });

        return () => ctx.revert();

    }, []);

    return (
        <div className={isMobile ? 'crewContentMobile' : 'crewContent'}>
            <div ref={containerRef} className={isMobile ? 'crewSectionJaneMobile' : 'crewSectionJane'}>
                <div className='crewName' style={{ fontSize: 30 }}>A film by</div>
                <div className='janeHeader'>Jane Abernethy</div>
                <CrewPic src={"/guts/jane.jpg"} isMobile={isMobile} />
            </div>


        </div>
    )
})

type CrewSection = {
    header: string,
    names: string[],
}

const CrewSection = React.memo(({ isMobile, sections, mobileImg }: { isMobile: boolean, sections: CrewSection[], mobileImg: string }) => {
    const containerRef = React.useRef<HTMLDivElement>(null);
    return (
        <div className='crewContent3'>

            <div className='crewCol' ref={containerRef} >
                {sections.map((section, index) => {
                    return (
                        <div className='crewSection' key={index}>
                            <div className='crewHeader'>{section.header}</div>
                            {section.names.map((name, index) => {
                                return <div key={index} className='crewName'>{name}</div>
                            })}

                        </div>
                    )
                })}
                <CrewPic src={mobileImg} isMobile={isMobile}/>
            </div>

        </div>)
});

const CoreCrew = React.memo(({ isMobile }: { isMobile: boolean }) => {
    const crewSections: CrewSection[] = [
        { header: "Director / Animator / Writer", names: ["Jane Abernethy"] },
        { header: "Producers", names: ["Vanessa Batten", "Amy Upchurch", "Katie Daniels"] },
        { header: "Executive Producers", names: ["Mark Simon Hewis", "Stuart Messinger", "Vanessa Batten", "Rachel Plant"] },
        { header: "Cinematography", names: ["George Milburn", "Dave Sproxton", "Nat Sale"] }
    ];
    return (
        <div className='crewContent3' >
            <CrewSection isMobile={isMobile} mobileImg={"/guts/jodie.jpg"} sections={crewSections} />
        </div>
    )
})

const CoreCrew2 = React.memo(({ isMobile }: { isMobile: boolean }) => {

    const containerRef = React.useRef<HTMLDivElement>(null);

    const crewSections: CrewSection[] = [
        { header: "Set Contruction", names: ["Tom Sewell", "George Milburn"] },
        { header: "Editing", names: ["Dan Williamson", "Megan McGregor", "Mark Simon Hewis"] },
        { header: "Rigging", names: ["Rich Modlen"] },
        { header: "Camera", names: ["Adam Cook"] },
        { header: "Dubbing Mixer", names: ["Will Davies"] },
        { header: "Color Grade", names: ["Carla Oliver Marti"] },
    ];

    return (
        <div className='crewContent3' >
            <div ref={containerRef} className='crewCol'>
                <CrewSection isMobile={isMobile} mobileImg={"/guts/dave.jpg"} sections={crewSections} />

            </div>
        </div>
    )
})


const Tutors = React.memo(({ isMobile }: { isMobile: boolean }) => {

    const containerRef = React.useRef<HTMLDivElement>(null);

    const crewSections: CrewSection[] = [
        { header: "Animation Tutors", names: ["Andy Symanowski", "Laurie Sitzia"] },
        { header: "Story Development Tutors", names: ["Rich Webber", "Sam Morrison", "Suzie Templeton"] },
        { header: "Storyboarding Tutor", names: ["Jay Clarke"] },
        { header: "Sound Design Tutor", names: ["Matt Loveridge"] },
        { header: "Set Dressing Tutor", names: ["Phil Davies"] },
        { header: "Model Making Tutors", names: ['Jim Parkyns', "Jack Slade"] },
        { header: "Compositing Tutors", names: ["Jim Lewis"] },
    ];

    return (
        <div className='crewContent3'>

            <div ref={containerRef} className='crewCol'>
                <CrewSection isMobile={isMobile} mobileImg={"/guts/nat.jpg"} sections={crewSections} />
            </div>

        </div>
    )
});

const TechSupport = React.memo(({ isMobile }: { isMobile: boolean }) => {
    const crewSections: CrewSection[] = [
        { header: "Studio Manager", names: ["Nat Sale"] },
        { header: "Senior Studio Support Technician", names: ["Adam Cook"] },
        { header: "Studio System Support Assistant", names: ["Adam Hanney", "Ollie Orwell"] },
        { header: "Studio Systems Support Engineer", names: ["Will Marshall"] },
        { header: "Senior Physical & Post Production Engineer", names: ["Paul Reeves"] },
        { header: "Digital Production Technology Manager", names: ["Toby Chilcot"] },
    ];

    return (
        <div className='crewContent3'>
            <CrewSection isMobile={isMobile} mobileImg={"/guts/rich.jpg"} sections={crewSections} />

        </div>
    )
});

const SpecialThanks = React.memo(({ isMobile }: { isMobile: boolean }) => {

    const crewSections: CrewSection[] = [
        {
            header: "Special Thanks",
            names: [
                "Rose Whelan",
                "Peter Lord",
                "Dave Sproxton",
                "Rich Webber",
                "Joseph Wallace",
                "Chesley Cannon",
                "Karin Csernohorski",
                "Lucy Davidson",
                "Jelle Jensen",
                "Laura Lago",
                "Dilgan Moczar",
                "Reem Moczar",
                "Marco Russo",
                "Camillo Sansi",
                "Tortor Smith",
                "Lily Ward",
                "Leo Wright",
            ]
        },

    ];

    return (
        <div className='crewContent3'>
            <CrewSection isMobile={isMobile} mobileImg={"/guts/issm2.jpg"} sections={crewSections} />
        </div>
    )
});


const CrewPic = ({ src, isMobile }: { src: string, isMobile: boolean }) => {
    const containerRef = React.useRef<HTMLDivElement>(null);

    React.useEffect(() => {
        let ctx = gsap.context(() => {

            if (!containerRef.current) {
                return
            }
            // Apply GSAP animation to each child div within the container
            gsap.fromTo(
                containerRef.current.children,
                { opacity: 0, y: 20 }, // Initial state
                {
                    opacity: 1,
                    y: 0,
                    stagger: GSAP_STAGGER,
                    duration: GSAP_DURATION,
                    scrollTrigger: {
                        trigger: containerRef.current,
                        start: GSAP_TRIGGER_MOBILE,
                        toggleActions: GSAP_TOGGLE_ACTIONS,
                    },
                }
            );
        });
        return () => ctx.revert();

    }, []);

    return (<div ref={containerRef} className={isMobile ? "mobileCrewImgContainer" : "desktopCrewImgContainer"} >
        <img src={src} className={isMobile ? "mobileCrewImg" : "desktopCrewImg"} />
    </div>)
}