
import React from 'react';
import './App.css';

import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

export const About = ({ isMobile, aboutRef, pic }: { isMobile: boolean, aboutRef: React.RefObject<HTMLDivElement>, pic: string }) => {

    const [posterNumber, setPosterNumber] = React.useState('1.png');
    const textRef = React.useRef<HTMLDivElement>(null);
  
    React.useEffect(() => {
      setPosterNumber(pic);
    }, [pic]);
  
  
    React.useEffect(() => {
  
      if (!aboutRef.current) {
        return
      }
  
  
      if (!isMobile) {
        gsap.fromTo(
          aboutRef.current.children,
          { opacity: 0, y: 20 }, // Initial state
          {
            opacity: 1,
            y: 0,
            stagger: 0.1, // Delay between each div animation
            duration: 0.5,
            scrollTrigger: {
              trigger: aboutRef.current,
              start: 'top 80%', // Trigger when container reaches 80% into viewport
              toggleActions: 'play none none none',
            },
          }
        );
      }
      else {
        if(!textRef.current) {
            return;
        }
        gsap.fromTo(
            textRef.current.children,
            { opacity: 0, y: 0 }, // Initial state
            {
              opacity: 1,
              y: 0,
              stagger: 0.2, // Delay between each div animation
              duration: 0.5,
              scrollTrigger: {
                trigger: textRef.current,
                start: 'top 80%', // Trigger when container reaches 80% into viewport
                toggleActions: 'play none none none',
              },
            }
          );
      }
    }, [isMobile]);
  
    return <div ref={aboutRef} className={isMobile ? 'aboutMobile' : 'about'}>
      <div onClick={() => {
        const currentNum = posterNumber;
        setPosterNumber("2.png");
  
        setTimeout(() => {
          setPosterNumber(currentNum);
        }, 90)
  
      }} className={isMobile ? "posterContainerMobile" : "posterContainer"}>
        <div className='poster' >
  
          <img className={isMobile ? 'posterImageMobile': 'posterImage'} src="/guts/PosterBg.jpg" />
          <img className='posterJane' style={{ opacity: posterNumber === "1.png" ? 1 : 0 }} src={"/guts/1.png"} />
          <img className='posterJane' style={{ opacity: posterNumber === "2.png" ? 1 : 0 }} src={"/guts/2.png"} />
          <img className='posterJane' style={{ opacity: posterNumber === "3.png" ? 1 : 0 }} src={"/guts/3.png"} />
          <img className='posterJane' style={{ opacity: posterNumber === "4.png" ? 1 : 0 }} src={"/guts/4.png"} />
          <img className='posterJane' style={{ opacity: posterNumber === "5.png" ? 1 : 0 }} src={"/guts/5.png"} />
          <img className='posterJane' style={{ opacity: posterNumber === "6.png" ? 1 : 0 }} src={"/guts/6.png"} />
          <img className='posterJane' style={{ opacity: posterNumber === "7.png" ? 1 : 0 }} src={"/guts/7.png"} />
          <img className='posterJane' style={{ opacity: posterNumber === "8.png" ? 1 : 0 }} src={"/guts/8.png"} />
          <img className='posterJane' style={{ opacity: posterNumber === "9.png" ? 1 : 0 }} src={"/guts/9.png"} />
          <img className='posterJane' style={{ opacity: posterNumber === "10.png" ? 1 : 0 }} src={"/guts/10.png"} />
        </div>
      </div>
      <div ref={textRef} className={isMobile ? "abouttextMobile" : "abouttext"}>
        <div className={isMobile ? 'aboutSectionMobile' : 'aboutSection'}>
          <div className='aboutHeader'>
            <div className='aboutHeaderText fredoka-title'>
              Synopsis
            </div>
          </div>
          <div className='aboutDesc'>
            Guts is a Romantic Comedy about the parts of ourselves we try to hide in order to feel accepted.
          </div>
        </div>
        <div className='aboutSection'>
          <div className='aboutHeader'>
            <div className='aboutHeaderText fredoka-title'>
              Duration
            </div>
          </div>
          <div className='aboutDesc'>2:45</div>
        </div>
        <div className='aboutSection'>
          <div className='aboutHeader'>
            <div className='aboutHeaderText fredoka-title'>
              Production Info
            </div>
          </div>
          <div className='aboutDesc'>“Guts” was created by Jane Abernethy at the <a href={"http://academy.aardman.com"} target={"_blank"}>Aardman Academy</a>, Bristol as part of their first “In Studio Stop Motion Course” - a 6 month course offered by the Academy,  where students learn all aspects of making a stop motion animated film, to create their own animated short.</div>
        </div>
      </div>
  
    </div>
  }
  