import React from 'react';
import './App.css';

import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

export const Stills = React.memo(({ stillsRef, isMobile, width, imagesRow1, imagesRow2 }: { stillsRef: React.RefObject<HTMLDivElement>, isMobile: boolean, width: number, imagesRow1: string[], imagesRow2: string[] }) => {
  console.log("re render")

  const topRowRef = React.useRef<HTMLDivElement>(null);
  const bottomRowRef = React.useRef<HTMLDivElement>(null);

  React.useEffect(() => {
    let ctx = gsap.context(() => {
      if (!topRowRef.current || !bottomRowRef.current) {
        return
      }


      gsap.fromTo(
        topRowRef.current,
        { x: isMobile ? "-250%" : "-50%"}, // Initial state
        {
          x: isMobile ? "0%" : "0%",
          stagger: 0.1, // Delay between each div animation
          duration: 0.5,
          scrollTrigger: {
            scrub: 0.5,
            end: 'bottom 0%',
            trigger: stillsRef.current,
            start: 'top 100%', // Trigger when container reaches 80% into viewport
            toggleActions: 'play none none reset',
          },
        }
      );
      gsap.fromTo(
        bottomRowRef.current,
        { x: isMobile ? "-150%" : "0%" }, // Initial state
        {
          x: isMobile ? "-400%" : "-50%",
          stagger: 0.1, // Delay between each div animation
          duration: 0.5,
          scrollTrigger: {
            scrub: 0.5,
            end: 'bottom 0%',
            trigger: stillsRef.current,
            start: 'top 100%', // Trigger when container reaches 80% into viewport
            toggleActions: 'play none none reset',
          },
        }
      );

      return () => { ctx.revert() }
    });

  }, [])

  return <div ref={stillsRef} className={isMobile ? 'stillsContainerMobile' : 'stillsContainer'} >
    <div className={isMobile ? 'stillsOuterMobile' : 'stillsOuter'}>
      <div ref={topRowRef} className='stillsInner'>
        {imagesRow1.map((i, index) => {
          return <div key={index} className='stillImageContainer'>
            <img className="stillImage" src={i} />
          </div>
        })}
      </div>

      <div ref={bottomRowRef} className='stillsInner'>
        {imagesRow2.map((i, index) => {
          return <div key={index} className='stillImageContainer'>
            <img className="stillImage" src={i} />
          </div>
        })}
      </div>
    </div>
  </div>
})